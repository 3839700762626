
import { Component, Vue } from 'vue-property-decorator';
import { UserRouteNames } from '@/types/users';
import SplitLayout from '../components/SplitLayout.vue';
import LoginForm from './components/LoginForm.vue';
import { Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';

@Component({
  components: {
    SplitLayout,
    LoginForm,
  },
})
export default class Login extends Vue {
  @Getter("userRouteNames", { namespace: namespaces.AuthModule })
  public userRouteNames!: UserRouteNames;

  get isMobileView(){
    return this.$vuetify.breakpoint.smAndDown
  }


  public onLoginSuccess() {
    this.$router.push(this.userRouteNames.DASHBOARD);
  }
}
